<template>
  <v-form v-model="isFromValid">
    <v-col cols="12" class="row mx-0 px-0">
      <v-col cols="6" class="py-0 pl-0">
        <v-text-field 
          v-model="card"
          :rules="cardRules"
          label="Номер карты"
        ></v-text-field>
      </v-col>

      <v-col cols="6" class="py-0 pr-0">
        <date-picker 
          v-model="valid" 
          label="Срок карты" 
          :gmt="gmt"
        ></date-picker>
      </v-col>
    </v-col>

    <v-col cols="12" class="row mx-0 my-0 px-0 py-0">
      <v-col cols="6" class="py-0 pl-0">
        <v-text-field 
          v-model="balance"
          :rules="balanceRules"
          label="Баланс кошелька"
        ></v-text-field>
      </v-col>

      <v-col cols="6" class="pr-0 py-0">
      </v-col>
    </v-col>

    <v-col cols="12" class="row mx-0 my-1 px-0 py-0">
      <v-col cols="4" class="py-0 pl-0">
        <v-btn
          color="primary"
          @click="preset(1999)"
          style="width: 100%;"
          outlined
        >1999 руб</v-btn>
      </v-col>
      <v-col cols="4" class="py-0" style="padding-left: 6px; padding-right: 6px;">
        <v-btn
          color="primary"
          @click="preset(2499)"
          style="width: 100%;"
          outlined
        >2499 руб</v-btn>
      </v-col>
      <v-col cols="4" class="py-0 pr-0">
        <v-btn
          color="primary"
          @click="preset(2999)"
          style="width: 100%;"
          outlined
        >2999 руб</v-btn>
      </v-col>
    </v-col>
  </v-form>
</template>

<script>
import DatePicker from './pickers/DatePicker.vue'
import TimePicker from './pickers/TimePicker.vue'

export default {
  name: 'BalEditor',

  components: {
    DatePicker,
    TimePicker,
  },

  model: {
    prop: 'valid',
    event: 'change',
  },

  props: {
    info: Object,
    isValid: Boolean,
  },

  data: () => ({
    gmt: 3,

    isFromValid: false,

    card: null,
    cardIcon: 'mdi-help-circle-outline', // mdi-check-circle-outline mdi-close-circle-outline mdi-help-circle-outline
    cardRules: [
      v => {
        if (v == null) return 'Необходимо заполнить';
        if (v.length != 10) return 'Неверная длина';
        for (let l of v.split('')) {
          if ('0123456789'.indexOf(l) == -1) return 'Запрещенные символы';
        }
        return true;
      }
    ],

    valid: null,

    balance: null,
    balanceRules: [
      v => ( v && v !== null ) || "Необходимо заполнить",
      v => ( v && v >= 0 ) || "Мин значение - 0",
      v => ( v && v <= 9999 ) || "Макс значение - 9999",
    ],
  }),

  computed: {},

  watch: {
    isFromValid: function() {
      this.$emit('change', this.isFromValid);
    },

    info: function() {
      this.reloadInfo();
    },
  },

  methods: {
    reloadInfo() {
      this.card = this.formatCard(this.info.card);
      this.valid = this.info.valid;
      this.balance = this.info.balance;
      this.cardIcon = (this.info.signed) ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline';
    },

    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    preset(balance) {
      // 01.02.2020 + random(1, 27) days
      this.valid = 1580504400 + this.getRandomInt(1, 27) * 86400;
      this.balance = balance;
    },

    formatCard(card) {
      card = String(card);
      while (card.length < 10) {
        card = '0' + card;
      }
      return card
    },
  },

  mounted() {
    this.reloadInfo();
    this.isFromValid = this.isValid;
  },

  updated() {
    this.info.card = parseInt(this.card);
    this.info.valid = parseInt(this.valid);
    this.info.balance = parseInt(this.balance);
  },
}
</script>

<style>

</style>