<template>
  <v-col cols="12" class="mt-0 px-0">
    <v-alert type="error">
      Приложение недоступно, повторите попытку позже
    </v-alert>
  </v-col>
</template>

<script>
export default {
  name: 'Maintenance',
}
</script>

<style>

</style>