<template>
  <v-app>    
    <v-main>
      <v-container style="max-width: 920px;">
        <v-col sm="12" md="6" lg="5" class="px-2 mx-auto">

          <alert 
            v-model="snackbar"
            :text="snackbarText"
            :timeout="snackbarTimeout"
          ></alert>

          <transition name="fade" mode="out-in">

            <loader v-if="step === 'loading'" key="loading"></loader>
            <maintenance v-if="step === 'maintenance'" key="maintenance"></maintenance>

            <div v-if="step == 'login'" key="login">
              <telegram-login
                mode="callback"
                telegram-login="troika_dedol_bot"
                @callback="loginCallback"
              />
              <!-- <v-btn
                class="mb-2"
                color="primary"
                @click="loginCallback({id: 1})"
                style="width: 100%;"
              >Login</v-btn> -->
            </div>

            <div v-if="step == 'forbidden'" key="forbidden">
              <h2 class="text-center mb-3">Доступ запрещен</h2>
              <p>Для получения доступа свяжитесь с <a href="https://t.me/dedol" target="_blank">dedol</a> и сообщите идентификатор вашего аккаунта Telegram: <code>{{ userId }}</code></p>
              <p>Описание возможностей приложения находится в <a href="https://github.com/dedol/troika-tat-hack" target="_blank">репозитории</a> GitHub.</p>
              <v-btn
                class="mb-2"
                color="error"
                @click="logout"
                style="width: 100%;"
              >Выход</v-btn>
            </div>

            <v-col v-if="step === 'menu'" key="menu" col="12" class="mx-0 my-0 px-0 py-0">
              <h2 class="text-center mb-3">Генератор билетов</h2>
              <v-btn
                class="mb-2"
                color="primary"
                @click="step = 'tat'"
                style="width: 100%;"
              >
                Создать ТАТ
              </v-btn>
              <v-btn
                class="mb-2"
                color="primary"
                @click="step = 'edn'"
                style="width: 100%;"
              >
                Создать Единый
              </v-btn>
              <v-btn
                class="mb-2"
                color="primary"
                @click="step = 'bal'"
                style="width: 100%;"
              >
                Пополнить баланс
              </v-btn>
              <v-btn
                class="mb-2"
                color="primary"
                @click="step = 'blocking'"
                style="width: 100%;"
              >
                Обход блокировок
              </v-btn>
              <v-btn
                class="mb-2"
                color="primary"
                @click="step = 'info'"
                style="width: 100%;"
              >
                Инструкция
              </v-btn>
              <v-btn
                class="mb-2"
                color="error"
                @click="logout"
                style="width: 100%;"
              >
                Выход
              </v-btn>
              <div class="text-center mt-1">
                Завершение сессии через 
                <v-chip color="primary" small>
                  {{ sessionTimeout }}
                </v-chip>
              </div>
              <div class="text-center text-caption mt-2">
                Версия 1.6 (7 октября 2023)
              </div>
            </v-col>

            <div v-if="step == 'tat'" key="tat">
              <breadcrumb 
                title="Создать ТАТ" 
                @menu="step = 'menu'"
              ></breadcrumb>
              <h2 class="text-center mb-3">Создать ТАТ</h2>
              <app-tat></app-tat>
            </div>

            <div v-if="step == 'edn'" key="edn">
              <breadcrumb 
                title="Создать Единый" 
                @menu="step = 'menu'"
              ></breadcrumb>
              <h2 class="text-center mb-3">Создать Единый</h2>
              <app-edn></app-edn>
            </div>

            <div v-if="step == 'bal'" key="bal">
              <breadcrumb 
                title="Пополнить баланс" 
                @menu="step = 'menu'"
              ></breadcrumb>
              <h2 class="text-center mb-3">Пополнить баланс</h2>
              <app-bal></app-bal>
            </div>

            <div v-if="step == 'info'" key="info">
              <breadcrumb 
                title="Инструкция" 
                @menu="step = 'menu'"
              ></breadcrumb>
              <info></info>
              <v-btn
                class="mb-2"
                color="primary"
                @click="step = 'menu'"
                style="width: 100%;"
              >В меню</v-btn>
            </div>

            <div v-if="step == 'blocking'" key="blocking">
              <breadcrumb 
                title="Обход блокировок" 
                @menu="step = 'menu'"
              ></breadcrumb>
              <blocking></blocking>
              <v-btn
                class="mb-2"
                color="primary"
                @click="step = 'menu'"
                style="width: 100%;"
              >В меню</v-btn>
            </div>

          </transition>
          
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import Alert from './components/Alert';
import Loader from './components/Loader';
import Maintenance from './components/Maintenance';

import AppTat from './components/AppTat';
import AppEdn from './components/AppEdn';
import AppBal from './components/AppBal';

import TelegramLogin from './components/TelegramLogin';
import Breadcrumb from './components/Breadcrumb';
import Info from './components/Info.vue'
import Blocking from './components/Blocking';

const server = 'https://troika.dedol.dev/api/';
const endpointStatus = server + 'status';
const endpointAuth = server + 'auth/telegram';

export default {
  name: 'App',

  components: {
    Alert,
    Loader,
    Maintenance,
    AppTat,
    AppEdn,
    AppBal,
    TelegramLogin,
    Breadcrumb,
    Info,
    Blocking,
  },

  data: () => ({
    step: 'loading',

    userId: null,
    tokenExpDate: 0,
    sessionTimeout: null,

    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 2000,
  }),

  computed: {

  },

  methods: {
    loginCallback(user) {
      this.userId = user.id;

      axios.post(endpointAuth, user)
        .then(response => {
          localStorage.setItem('token', response.data.token);
          this.loadTokenExpDate();
          this.step = 'menu';
        })
        .catch(error => {
          if (error.response.status == 403) {
            this.step = 'forbidden';
            return false;
          }
          this.message('Ошибка авторизации')
        })
    },

    loadTokenExpDate() {
      let token = localStorage.getItem('token');

      try {
        let data = JSON.parse(atob(token.split('.')[1]));
        this.tokenExpDate = data.exp;
      }
      catch {
        this.tokenExpDate = 0;
      }
    },

    isTokenValid() {
      return this.tokenExpDate * 1000 > new Date().getTime();
    },

    logout() {
      localStorage.removeItem('token');
      this.step = 'login';
    },

    message(text) {
      this.snackbar = false;
      this.snackbarText = text;
      this.snackbar = true;
    },

    updateSessionTimeout() {
      let timeout = this.tokenExpDate * 1000 - new Date().getTime();
      if (timeout <= 0) {
        this.sessionTimeout = '00:00';
        return false;
      }

      let seconds = parseInt(timeout / 1000);
      let m = ('0' + String(parseInt(seconds/60))).slice(-2);
      let s = ('0' + String(parseInt(seconds - m*60))).slice(-2);
      this.sessionTimeout = `${m}:${s}`;
    },
  },

  mounted() {
    this.loadTokenExpDate();
    setInterval(() => this.updateSessionTimeout(), 1000);

    axios.get(endpointStatus)
    .then(response => {
      if (this.isTokenValid()) {
        this.step = 'menu';
      }
      else {
        this.step = 'login';
      }    
    })
    .catch(data => {
      console.log(data);
      this.step = 'maintenance';
    })
  },
};
</script>

<style>
  textarea {
    font-family: 'Courier New', Courier, monospace;
    padding-bottom: 4px !important;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .v-breadcrumbs li:nth-child(even) {
    padding: 0 8px;
  }
</style>