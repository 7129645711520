<template>
  <div>
    <alert 
      v-model="snackbar"
      :text="snackbarText"
      :timeout="snackbarTimeout"
    ></alert>

    <transition name="fade" mode="out-in">
      <v-col class="px-0 pt-1" v-if="step === 1" key="step1">
        <!-- <v-col cols="12" class="px-0 pt-0 pb-4">
          <v-alert type="info" class="mb-0" outlined>
            Вставьте данные из <b>4 сектора</b> карты
          </v-alert>
        </v-col> -->

        <v-form v-model="readButtonAvailable">
          <v-textarea
            v-model="dump"
            :rules="dumpRules"
            label="Данные с карты"
            rows="4"
            hide-details="auto"
            outlined
          ></v-textarea>
        </v-form>

        <v-col col="12" class="row mx-0 my-0 px-0 py-0 mt-3">
          <v-btn
            color="primary" 
            @click="step = 2" 
            style="width: 100%;"
            outlined
          >Ввести данные вручную</v-btn>
        </v-col>

        <v-col col="12" class="row mx-0 my-0 px-0 py-0 mt-3">
          <v-col col="6" class="pl-0 py-0">
            <v-btn 
              color="primary" 
              @click="pasteDump" 
              style="width: 100%;"
            >Вставить</v-btn>
          </v-col>
          <v-col col="6" class="pr-0 py-0">
            <v-btn 
              color="primary" 
              @click="parseCard" 
              style="width: 100%;"
              :loading="parseLoading"
              :disabled="!readButtonAvailable"
            >Далее</v-btn>
          </v-col>
        </v-col>
      </v-col>

      <v-col class="px-0 py-0 mt-3" v-if="step === 2" key="step2">
        <tat-editor 
          v-model="createButtonAvailable"
          :info="info"
        ></tat-editor>

        <v-col col="12" class="row mx-0 my-0 px-0 py-0 mt-3">
          <v-btn
            color="primary" 
            @click="clearCard" 
            style="width: 100%;"
            :loading="clearLoading"
            :disabled="!createButtonAvailable"
            outlined
          >Удалить билет</v-btn>
        </v-col>

        <v-col col="12" class="row mx-0 my-0 px-0 py-0 mt-3">
          <v-col col="6" class="pl-0 py-0">
            <v-btn 
              color="error"
              @click="step = 1"
              style="width: 100%;"
            >Назад</v-btn>
          </v-col>
          <v-col col="6" class="pr-0 py-0">
            <v-btn
              color="primary" 
              @click="createCard" 
              style="width: 100%;"
              :loading="createLoading"
              :disabled="!createButtonAvailable"
            >Создать</v-btn>
          </v-col>
        </v-col>
      </v-col>

      <v-col class="px-0 py-0 mt-3" v-if="step === 3" key="step3">
        <v-textarea
          v-model="result"
          label="Результат"
          rows="4"
          hide-details="auto"
          outlined
        ></v-textarea>

        <v-col col="12" class="row mx-0 my-0 px-0 py-0 mt-2">
          <v-col col="6" class="pl-0">
            <v-btn
              color="error"
              @click="step = 2"
              style="width: 100%;"
            >Назад</v-btn>
          </v-col>
          <v-col col="6" class="pr-0">
            <v-btn
              color="primary"
              @click="copyToClipboard"
              style="width: 100%;"
            >Скопировать</v-btn>
          </v-col>
        </v-col>
      </v-col>
    </transition>
  </div>
</template>

<script>
import Alert from './Alert';
import TatEditor from './TatEditor';

const server = 'https://troika.dedol.dev/api/';
const endpointParse = server + 'tat/parse';
const endpointCreate = server + 'tat/create';
const endpointClear = server + 'tat/clear';

export default {
  name: 'AppTat',

  components: {
    Alert,
    TatEditor,
  },

  data: () => ({
    step: 1,

    info: {
      card: 42012345,
      type: 22,
      valid: 694126800,
      start: 694126800,
      finish: 694126800,
      last: 694126800,
      terminal: 0,
      trips: 0,
      signed: true,
    },

    readButtonAvailable: false,
    createButtonAvailable: false,

    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 2000,

    // dump: '421020254DA1262DBA014D4000006F0A\n23F95E395E115EF8000003D0000258B7\n23F95E395E115EF8000003D0000258B7\n73068F118C13787788002B7F3253FAC5',
    dump: null,
    dumpRules: [ 
      v => {
        if (v == null) return 'Неполные данные из сектора';
        let rows = v.split('\n');
        if (rows.length != 4) return 'Неполные данные из сектора';
        if (rows[3] != '73068F118C13787788002B7F3253FAC5') return 'Неверный сектор';
        for (let row of rows) {
          if (row.length != 32) return 'Неверная длина строки';
          for (let l of row) {
            if ('0123456789ABCDEF'.indexOf(l) == -1) return 'Неподдерживаемые символы';
          }
        }
        return true;
      }
    ],

    parseLoading: false,
    createLoading: false,
    clearLoading: false,

    result: null,
  }),

  computed: {

  },

  methods: {
    parseCard() {
      let data = this.dump.split('\n');
      let headers = {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }

      this.parseLoading = true;
      axios.post(endpointParse, {data}, {headers})
        .then(response => {
          this.info = response.data;
          this.step = 2;
        })
        .catch(error => {
          if (error.response.status == 400) {
            this.message('Ошибка в данных');
          }
          else if (error.response.status == 401) {
            this.message('Ошибка авторизации');
          }
          else if (error.response.status == 403) {
            this.message('Ошибка доступа');
          }
          else {
            this.message('Ошибка сервера');
            console.log(error);
          }
        })
        .finally(() => {
          this.parseLoading = false;
        })
    },

    createCard() {
      let info = this.info;
      let headers = {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }

      this.createLoading = true;
      axios.post(endpointCreate, info, {headers})
        .then(response => {
          this.result = response.data.data.join('\n');
          this.step = 3;
        })
        .catch(error => {
          if (error.response.status == 400) {
            this.message('Ошибка в данных');
          }
          else if (error.response.status == 401) {
            this.message('Ошибка авторизации');
          }
          else if (error.response.status == 403) {
            this.message('Ошибка доступа');
          }
          else {
            this.message('Ошибка сервера');
            console.log(error);
          }
        })
        .finally(() => {
          this.createLoading = false;
        })
    },

    clearCard() {
      let info = this.info;
      let headers = {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }

      this.clearLoading = true;
      axios.post(endpointClear, info, {headers})
        .then(response => {
          this.result = response.data.data.join('\n');
          this.step = 3;
        })
        .catch(error => {
          if (error.response.status == 400) {
            this.message('Ошибка в данных');
          }
          else if (error.response.status == 401) {
            this.message('Ошибка авторизации');
          }
          else if (error.response.status == 403) {
            this.message('Ошибка доступа');
          }
          else {
            this.message('Ошибка сервера');
            console.log(error);
          }
        })
        .finally(() => {
          this.clearLoading = false;
        })
    },

    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.result);
        this.message('Успешно!');
      } catch (error) {
        this.message(error);
      }
    },

    async pasteDump() {
      try {
        this.dump = await navigator.clipboard.readText();
        this.message('Успешно!');
      } catch (error) {
        this.message(error)
      }
    },

    message(text) {
      this.snackbar = false;
      this.snackbarText = text;
      this.snackbar = true;
    }
  },
}
</script>

<style>

</style>