<template>
  <v-dialog
    ref="time"
    v-model="show"
    :return-value.sync="time"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="show"
      v-model="time"
      full-width
      format="24hr"
      @click:minute="pickTime"
    >
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: 'TimePicker',

  model: {
    prop: 'seconds',
    event: 'change',
  },

  props: {
    seconds: Number,
    label: String,
    gmt: Number,
  },

  data: () => ({
    show: false,
    time: '00:01',
  }),

  computed: { },

  watch: {
    seconds() {
      this.time = this.getTime(this.seconds);
    }
  },

  methods: {
    getTime(timestamp) {
      let s = (timestamp) % 86400;
      let h = ('0' + String(parseInt(s/3600))).slice(-2);
      let m = ('0' + String(parseInt((s%3600)/60))).slice(-2);
      return `${h}:${m}`
    },

    getSeconds(time) {
      let [h, m] = time.split(':');
      let seconds = parseInt(h) * 3600 + parseInt(m) * 60;
      let result = (86400 + seconds) % 86400;
      return result;
    },

    pickTime() {
      this.$refs.time.save(this.time);
      this.$emit('change', this.getSeconds(this.time));
    },
  },

  mounted() {
    this.time = this.getTime(this.seconds);
  },
}
</script>

<style>

</style>