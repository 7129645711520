<template>
  <div>
    <h2 class="text-center mb-3">Инструкция</h2>
    <h3>Настройка MCT</h3>
    <p class="mb-2">Скачать приложение <a href="https://play.google.com/store/apps/details?id=de.syss.MifareClassicTool" target="_blank">Mifare Classic Tool</a>. В главном меню выбрать раздел <code>Edit/Add key file</code>. Плюсик в правом верхнем углу, имя файла любое. Вставить 6 ключей и сохранить.</p>
    <div class="mb-3">
      <code>73068F118C13</code><br>
      <code>2B7F3253FAC5</code><br>
      <code>AE3D65A3DAD4</code><br>
      <code>0F1C63013DBA</code><br>
      <code>A73F5DC1D333</code><br>
      <code>E35173494A81</code><br>
    </div>
    <h3>Создание ТАТ</h3>
    <p>Для записи <b>необходимо</b> использовать карты, купленные 10-14 дней назад и обязательно с совершенными поездками. При записи на новую и чистую карту будет 100% блокировка на следующий день после первого проезда.</p>
    <p>В главном меню выбрать раздел <code>Read tags</code>. <b>Диапазон секторов 4-4.</b> Выбрать только один, созданный ранее файл ключей. Приложить карту и нажать кнопку <code>Start mapping and read tag</code>.</p>
    <p>Скопировать все данные из <b>4 сектора</b> и вставить в раздел <b>Создать ТАТ</b> на сайте. Настроить необходимые параметры и создать билет. Скопировать сгенерированные данные и вставить их в приложение на телефоне.</p>
    <p>Три точки в правом верхнем углу, пункт <code>Write dump</code>. Выбрать <code>Write dump (clone)</code> и нажать <code>Write dump</code>. Приложить карту и нажать <code>OK</code>. Если запись прошла успешно, то должны отобразиться новые данные в секторе. Билет записан, можно пользоваться.</p>
    <h3>Создание Единого</h3>
    <p>Для записи <b>рекомендуется</b> использовать карты, купленные 10-14 дней назад и с совершенными поездками. При записи на новую и чистую карту шанс блокировки через несколько дней значительно выше.</p>
    <p>В главном меню выбрать раздел <code>Read tags</code>. <b>Диапазон секторов 7-7.</b> Выбрать созданный ранее файл ключей. Приложить карту и нажать кнопку <code>Start mapping and read tag</code>.</p>
    <p>Скопировать все данные из <b>7 сектора</b> и вставить в раздел <b>Создать Единый</b> на сайте. Настроить необходимые параметры и создать билет. Скопировать сгенерированные данные и вставить их в приложение на телефоне.</p>
    <p>Три точки в правом верхнем углу, пункт <code>Write dump</code>. Выбрать <code>Write dump (clone)</code> и нажать <code>Write dump</code>. Приложить карту и нажать <code>OK</code>. Если запись прошла успешно, то должны отобразиться новые данные в секторе.</p>
    <p>Теперь нужно активировать билет. Для этого необходимо приобрести Единый на 1 сутки (285 руб) <b>в автомате в метро</b>. Активация через любые приложения не работает, только через автомат в метро.</p>
    <p>После покупки билета в автомате должно отобразиться два билета: сгерерированный и Единый на 1 день. Билет записан, можно пользоваться.</p>
    <p>После записи дампа и до момента покупки Единого на 1 сутки <b>не рекомендуется</b> проверять билет в приложениях на телефоне (с подключенным интернетом) и других терминалах, так как это увеличивает шанс блокировки карты.</p>
    <h3>Пополнение баланса</h3>
    <p>Не работает. Когда будет работать и будет ли вообще - неизвестно. Те карты с балансом, которые продаются сейчас у разных продавцов, были записаны ранее, до 19 января 2023 года. На данный момент работающих способов создания баланса не существует.</p>
  </div>
</template>

<script>
export default {
  name: 'Info',
}
</script>

<style>

</style>