<template>
  <!-- Vuetify stack snackbars: https://www.codeply.com/p/Zm6qNmPCNv -->
  <v-snackbar
      v-model="active"
      :timeout="timeout"
    >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="blue"
        text
        v-bind="attrs"
        @click="active = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Alert',

  model: {
    prop: 'show',
    event: 'change',
  },

  props: {
    show: Boolean,
    text: String,
    timeout: Number,
  },

  data: () => ({
    active: false,
  }),

  watch: {
    show: function() {
      this.active = this.show;
    },
    active: function() {
      this.$emit('change', this.active);
    }
  },
}
</script>

<style>
</style>