<template>
  <v-dialog
    ref="picker"
    v-model="show"
    :return-value.sync="iso"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
    <v-text-field
      v-model="formatted"
      :label="label"
      readonly
      v-bind="attrs"
      v-on="on"
    ></v-text-field>
    </template>
    <v-date-picker
      v-model="iso"
      scrollable
      @input="pickDate"
      min="1991-12-31"
      max="2036-11-07"
    >
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  name: 'DatePicker',

  model: {
    prop: 'timestamp',
    event: 'change',
  },

  props: {
    timestamp: Number,
    label: String,
    gmt: Number,
  },

  data: () => ({
    show: false,
    iso: '1970-01-01',
  }),

  computed: {
    formatted: function() {
      let iso = this.getISODate(this.timestamp);
      let [y, m, d] = iso.split('-');
      return `${d}.${m}.${y}`;
    },
  },

  watch: {
    timestamp() {
      this.iso = this.getISODate(this.timestamp);
    }
  },

  methods: {
    getISODate(timestamp) {
      // Увеличение таймштампа на gmt*3600 и последующий парсинг через UTC методы
      // позволяют зафиксировать указанный UTC при любом часовом поясе пользователя
      let date = new Date((timestamp + this.gmt * 3600) * 1000);
      let d = ('0' + date.getUTCDate()).slice(-2);
      let m = ('0' + (date.getUTCMonth() + 1)).slice(-2);
      let y = date.getUTCFullYear();
      return `${y}-${m}-${d}`;
    },

    pickDate() {
      // При переводе даты из ISO в таймштамп требуется уменьшить тш на gmt*3600
      this.$refs.picker.save(this.iso);
      let changed = (new Date(this.iso).getTime() / 1000) - this.gmt * 3600;
      this.$emit('change', changed);
    },
  },

  mounted() {
    this.iso = this.getISODate(this.timestamp);
  },
}
</script>

<style>

</style>