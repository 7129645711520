<template>
  <v-form v-model="isFromValid">
    <v-col cols="12" class="row mx-0 px-0">
      <v-col cols="6" class="py-0 pl-0">
        <v-text-field 
          v-model="card"
          :rules="cardRules"
          label="Номер карты"
          :append-icon="cardIcon"
        ></v-text-field>
      </v-col>

      <v-col cols="6" class="py-0 pr-0">
        <date-picker 
          v-model="valid" 
          label="Срок карты" 
          :gmt="gmt"
        ></date-picker>
      </v-col>
    </v-col>

    <v-col cols="12" class="row mx-0 my-0 px-0 py-0">
      <v-col cols="6" class="py-0 pl-0">
        <v-select 
          v-model="type" 
          :rules="typeRules"
          :items="typeItems" 
          label="Тип билета"
          @input="startHandler"
        ></v-select>
      </v-col>

      <v-col cols="6" class="py-0 pr-0">
        <date-picker 
          v-model="start" 
          label="Дата покупки" 
          :gmt="gmt"
          @change="startHandler"
        ></date-picker>
      </v-col>
    </v-col>

    <v-col cols="12" class="row mx-0 my-0 px-0 py-0">
      <v-col cols="6" class="py-0 pl-0">
        <date-picker 
          v-model="finish" 
          label="Действителен до" 
          :gmt="gmt"
        ></date-picker>
      </v-col>

      <v-col cols="6" class="pr-0 py-0">
        
      </v-col>
    </v-col>

    <v-col cols="12" class="row mx-0 my-1 px-0 py-0">
      
    </v-col>

    <v-col cols="12" class="row mx-0 my-1 px-0 py-0">
      <v-col cols="6" class="py-0 pl-0">
        <v-btn
          color="primary"
          @click="presetType(4064)"
          style="width: 100%;"
          outlined
        >30 дней</v-btn>
      </v-col>
      <v-col cols="6" class="py-0 pr-0">
        <v-btn
          color="primary"
          @click="presetType(3560)"
          style="width: 100%;"
          outlined
        >60 поездок</v-btn>
      </v-col>
    </v-col>
  </v-form>
</template>

<script>
import DatePicker from './pickers/DatePicker.vue'
import TimePicker from './pickers/TimePicker.vue'

export default {
  name: 'EdnEditor',

  components: {
    DatePicker,
    TimePicker,
  },

  model: {
    prop: 'valid',
    event: 'change',
  },

  props: {
    info: Object,
    isValid: Boolean,
  },

  data: () => ({
    gmt: 3,

    isFromValid: false,

    card: null,
    cardIcon: 'mdi-help-circle-outline', // mdi-check-circle-outline mdi-close-circle-outline mdi-help-circle-outline
    cardRules: [
      v => {
        if (v == null) return 'Необходимо заполнить';
        if (v.length != 10) return 'Неверная длина';
        for (let l of v.split('')) {
          if ('0123456789'.indexOf(l) == -1) return 'Запрещенные символы';
        }
        return true;
      }
    ],

    valid: null,

    daysCount: null,
    daysCountRules: [
      v => {
        if (v < 0 || v > 365) return 'Неверное количество'
        return true;
      }
    ],

    type: 4064,
    typeRules: [
      v => {
        if (![3560, 4064, 4067].includes(v)) return 'Необходимо выбрать';
        return true;
      }
    ],
    typeItems: [
      {
        text: '30 дней',
        value: 4064,
      },
      {
        text: '30 дней Пригород',
        value: 4067,
      },
      {
        text: '60 поездок',
        value: 3560,
      }
    ],

    start: null,
    finish: null,
    lastDate: null,
    lastTime: null,

    terminal: null,
    trips: null,
    counterRules: [
      v => ( v && v !== null ) || "Необходимо заполнить",
      v => ( v && v >= 0 ) || "Мин значение - 0",
      v => ( v && v <= 65535 ) || "Макс значение - 65535",
    ],
  }),

  computed: {},

  watch: {
    isFromValid: function() {
      this.$emit('change', this.isFromValid);
    },

    info: function() {
      this.reloadInfo();
    },
  },

  methods: {
    reloadInfo() {
      this.card = this.formatCard(this.info.card);
      this.type = this.info.type;
      this.valid = this.info.valid;
      this.start = this.info.start;
      this.finish = this.info.finish;
      this.cardIcon = (this.info.signed) ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline';
    },

    getDayStartTime(timestamp) {
      // Получение таймштампа на момент 00:00 этого же дня
      return parseInt((timestamp + this.gmt * 3600) / 86400) * 86400 - this.gmt * 3600;
    },

    startHandler() {
      // Обработка изменения старт даты: меняется конечная дата
      let days = 30;
      if (this.type == 3560) days = 60;

      this.finish = this.start + (days - 1) * 86400;
    },

    presetType(type) {
      let today = parseInt(new Date().getTime() / 1000);
      this.start = this.getDayStartTime(today);
      this.type = type;
      this.startHandler();
    },

    formatCard(card) {
      card = String(card);
      while (card.length < 10) {
        card = '0' + card;
      }
      return card
    },
  },

  mounted() {
    this.reloadInfo();
    this.isFromValid = this.isValid;
  },

  updated() {
    this.info.card = parseInt(this.card);
    this.info.type = parseInt(this.type);
    this.info.valid = parseInt(this.valid);
    this.info.start = parseInt(this.start);
    this.info.finish = parseInt(this.finish);
  },
}
</script>

<style>

</style>