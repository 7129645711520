<template>
  <v-breadcrumbs :items="items" class="pa-0 pb-2">
    <template v-slot:divider class="px-2">
      <v-icon>mdi-chevron-right</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item 
        :style="item.style"
        @click.native="if (!item.disabled) $emit('menu');"
        :disabled="item.disabled"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'Breadcrumb',

  props: {
    title: String,
  },

  data: () => ({
    items: [
      {
        text: 'Меню',
        disabled: false,
        style: 'cursor: pointer; color: #1976d2;',
      },
      {
        text: 'title',
        disabled: true,
        style: '',
      },
    ],
  }),

  mounted() {
    this.items[1].text = this.title;
  },
}
</script>

<style>
</style>