<template>
  <v-form v-model="isFromValid">
    <v-col cols="12" class="row mx-0 px-0">
      <v-col cols="6" class="py-0 pl-0">
        <v-text-field 
          v-model="card"
          :rules="cardRules"
          label="Номер карты"
          :append-icon="cardIcon"
        ></v-text-field>
      </v-col>

      <v-col cols="6" class="py-0 pr-0">
        <date-picker 
          v-model="valid" 
          label="Срок карты" 
          :gmt="gmt"
        ></date-picker>
      </v-col>
    </v-col>

    <v-col cols="12" class="row mx-0 my-0 px-0 py-0">
      <v-col cols="6" class="py-0 pl-0">
        <v-text-field 
          v-model="daysCount" 
          :rules="daysCountRules"
          label="Осталось дней"
          type="number"
          min="0"
          max="365"
          @input="daysCountHandler"
        ></v-text-field>
      </v-col>

      <v-col cols="6" class="py-0 pr-0">
        <v-select 
          v-model="type" 
          :rules="typeRules"
          :items="typeItems" 
          label="Тип билета"
          @input="typeHandler"
        ></v-select>
      </v-col>
    </v-col>

    <v-col cols="12" class="row mx-0 my-0 px-0 py-0">
      <v-col cols="6" class="py-0 pl-0">
        <date-picker 
          v-model="start" 
          label="Дата покупки" 
          :gmt="gmt"
          @change="startHandler"
        ></date-picker>
        <!-- @input="$refs.start.save(start); updateFinish();" -->
      </v-col>

      <v-col cols="6" class="pr-0 py-0">
        <date-picker 
          v-model="finish" 
          label="Действителен до" 
          :gmt="gmt"
        ></date-picker>
      </v-col>
    </v-col>

    <v-col cols="12" class="row mx-0 my-0 px-0 py-0">
      <v-col cols="6" class="py-0 pl-0">
        <date-picker 
          v-model="lastDate" 
          label="Дата поездки" 
          :gmt="gmt"
        ></date-picker>
      </v-col>

      <v-col cols="6" class="pr-0 py-0">
        <time-picker 
          v-model="lastTime" 
          label="Время поездки" 
          :gmt="gmt"
        ></time-picker>
      </v-col>
    </v-col>

    <v-col cols="12" class="row mx-0 my-0 px-0 py-0">
      <v-col cols="6" class="pl-0 py-0">
        <v-text-field
          v-model="terminal"
          :rules="counterRules"
          label="Валидатор"
          type="number"
          min="0"
          max="65535"
        ></v-text-field>
      </v-col>

      <v-col cols="6" class="pr-0 py-0">
        <v-text-field
          v-model="trips"
          :rules="counterRules"
          label="Номер поездки"
          type="number"
          min="0"
          max="65535"
        ></v-text-field>
      </v-col>
    </v-col>

    <v-col cols="12" class="row mx-0 my-1 px-0 py-0">
      <v-col cols="4" class="py-0 pl-0">
        <v-btn
          color="primary"
          @click="presetDays(5)"
          style="width: 100%;"
          outlined
        >5 дней</v-btn>
      </v-col>
      <v-col cols="4" class="py-0" style="padding-left: 6px; padding-right: 6px;">
        <v-btn
          color="primary"
          @click="presetDays(30)"
          style="width: 100%;"
          outlined
        >30 дней</v-btn>
      </v-col>
      <v-col cols="4" class="py-0 pr-0">
        <v-btn
          color="primary"
          @click="presetDays(90)"
          style="width: 100%;"
          outlined
        >90 дней</v-btn>
      </v-col>
    </v-col>
  </v-form>
</template>

<script>
import DatePicker from './pickers/DatePicker.vue'
import TimePicker from './pickers/TimePicker.vue'

export default {
  name: 'TatEditor',

  components: {
    DatePicker,
    TimePicker,
  },

  model: {
    prop: 'valid',
    event: 'change',
  },

  props: {
    info: Object,
    isValid: Boolean,
  },

  data: () => ({
    gmt: 3,

    isFromValid: false,


    card: null,
    cardIcon: 'mdi-help-circle-outline', // mdi-check-circle-outline mdi-close-circle-outline mdi-help-circle-outline
    cardRules: [
      v => {
        if (v == null) return 'Необходимо заполнить';
        if (v.length != 10) return 'Неверная длина';
        for (let l of v.split('')) {
          if ('0123456789'.indexOf(l) == -1) return 'Запрещенные символы';
        }
        return true;
      }
    ],

    valid: null,

    daysCount: null,
    daysCountRules: [
      v => {
        if (v < 0 || v > 365) return 'Неверное количество'
        return true;
      }
    ],

    type: null,
    typeRules: [
      v => {
        if (v < 22 || v > 24) return 'Необходимо выбрать';
        return true;
      }
    ],
    typeItems: [
      {
        text: '30 дней',
        value: 22,
      },
      {
        text: '90 дней',
        value: 23,
      },
      {
        text: '365 дней',
        value: 24,
      },
    ],

    start: null,
    finish: null,
    lastDate: null,
    lastTime: null,

    terminal: null,
    trips: null,
    counterRules: [
      v => ( v && v !== null ) || "Необходимо заполнить",
      v => ( v && v >= 0 ) || "Мин значение - 0",
      v => ( v && v <= 65535 ) || "Макс значение - 65535",
    ],
  }),

  computed: {},

  watch: {
    isFromValid: function() {
      this.$emit('change', this.isFromValid);
    },

    info: function() {
      this.reloadInfo();
    },
  },

  methods: {
    reloadInfo() {
      this.card = this.formatCard(this.info.card);
      this.type = this.info.type;
      this.valid = this.info.valid;
      this.start = this.info.start;
      this.finish = this.info.finish;
      this.lastDate = parseInt((this.info.last + this.gmt * 3600) / 86400) * 86400 - this.gmt * 3600;
      this.lastTime = (this.info.last + this.gmt * 3600) % 86400;
      this.terminal = String(this.info.terminal);
      this.trips = String(this.info.trips);
      this.cardIcon = (this.info.signed) ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline';

      // let today = parseInt(new Date().getTime() / 1000);
      // this.daysCount = parseInt((this.info.finish - today) / 86400) + 2; // +2 учитывать сегодняшний день
      // if (this.daysCount < 0) this.daysCount = 0;
      this.calculateDaysCount();
    },

    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    getDayStartTime(timestamp) {
      // Получение таймштампа на момент 00:00 этого же дня
      return parseInt((timestamp + this.gmt * 3600) / 86400) * 86400 - this.gmt * 3600;
    },

    daysCountHandler() {
      // Обработка ручного изменения количества дней:
      // изменяется тип билета, стартовая и финишная даты
      if (this.daysCount === null) return 0;
      if (this.daysCount < 1 || this.daysCount > 365) return 0;
      
      let typeDays = 30;
      if (this.daysCount > 30 && this.daysCount <= 90) {
        typeDays = 90;
        this.type = 23;
      }
      else if (this.daysCount > 90) {
        typeDays = 365;
        this.type = 24;
      }
      else {
        this.type = 22;
      }

      let today = parseInt(new Date().getTime() / 1000);
      this.finish = this.getDayStartTime(today) + (this.daysCount - 1) * 86400;
      this.start = this.finish - 86400 * (typeDays - 1);
      this.lastDate = this.getDayStartTime(today);
      this.lastTime = (today + this.gmt * 3600) % 86400;
      this.terminal = this.getRandomInt(1, 511);
      this.trips = 1;
    },

    typeHandler() {
      // Обработка изменения типа билета: меняется количество дней и финиш-дата
      let typeDays = 30;
      if (this.type == 23) {
        typeDays = 90;
      }
      else if (this.type == 24) {
        typeDays = 365;
      }

      this.finish = this.start + (typeDays - 1) * 86400;
      this.calculateDaysCount();
    },

    startHandler() {
      // Обработка изменения старт даты: меняется конечная дата и кол-во дней
      let typeDays = 30;
      if (this.type == 23) {
        typeDays = 90;
      }
      else if (this.type == 24) {
        typeDays = 365;
      }

      this.finish = this.start + (typeDays - 1) * 86400;
      this.calculateDaysCount();
    },

    presetDays(days) {
      this.daysCount = days;
      this.daysCountHandler();
    },

    formatCard(card) {
      card = String(card);
      while (card.length < 10) {
        card = '0' + card;
      }
      return card
    },

    calculateDaysCount() {
      let today = this.getDayStartTime(parseInt(new Date().getTime() / 1000));
      
      // Если билет еще не начал действовать - дата старта больше, чем текущая
      if (this.start > today) {
        this.daysCount = parseInt((this.finish - this.start) / 86400) + 1; // +1 учитывать сегодняшний день
      }
      else {
        this.daysCount = parseInt((this.finish - today) / 86400) + 1; // +1 учитывать сегодняшний день
      }
      if (this.daysCount < 0) this.daysCount = 0;
    },
  },

  mounted() {
    this.reloadInfo();
    this.isFromValid = this.isValid;
  },

  updated() {
    this.info.card = parseInt(this.card);
    this.info.type = parseInt(this.type);
    this.info.valid = parseInt(this.valid);
    this.info.start = parseInt(this.start);
    this.info.finish = parseInt(this.finish);
    this.info.last = parseInt(this.lastDate) + parseInt(this.lastTime);
    this.info.terminal = parseInt(this.terminal);
    this.info.trips = parseInt(this.trips);
  },
}
</script>

<style>

</style>