<template>
  <div>
    <h2 class="text-center mb-3">Обход блокировок</h2>
    <p class="mb-4">100% способа обхода блокировок не существует, но приведенные ниже советы помогут минимизировать риск блокировки вашей карты.</p>
    <h3 class="mb-1 pl-6">Общие правила</h3>
    <ul class="mb-4">
      <li>Карта должна быть купленна более 10 дней назад. Если карта будет куплена 2 и более месяца назад, то шанс, что дадут блокировку стремится к нулю.</li>
      <li>Для наземного транспорта и для метро желательно использовать разные карты. Перед записью билета рекомендуется сделать 1-2 поездки по кошельку.</li>
      <li>Если билет требует активации, то не рекомендуется проверять карту в приложениях на телефоне и любых других терминалах до момента активации в автомате метро.</li>
    </ul>

    <h3 class="mb-1 pl-6">Наземный транспорт</h3>
    <ul class="mb-2">
      <li>На карте для наземного транспорта должен быть записан только ТАТ или только Единый. Не рекомендуется записывать оба билета.</li>
      <li>Желательно использовать ТАТ на 30 дней, а не на 90 или 365. Тогда никаких вопросов от контроллеров не будет.</li>
      <li>При создании ТАТ обязательно установить счетчик поездок на 1 поездку, а также указать время и дату последней поездки.</li>
    </ul>
    <p>Это всё можно сделать одним кликом по кнопке “30 дней” в интерфейсе создания ТАТ.</p>

    <h3 class="mb-1 pl-6">Метро</h3>
    <ul class="mb-4">
      <li>На карте для метро должен быть записан только Единый или только баланс.</li>
      <li>Желательно избегать проходов в крайние турникеты, для прохода использовать только центральные.</li>
      <li>Возможно, избежать блокировки помогает отлёжка карты после записи в течение 8-10 дней. То есть просто не используем карту первые 8 дней, а потом катаемся оставшиеся дни как обычно.</li>
    </ul>

    <h3 class="mb-1 pl-6">Пополнение баланса</h3>
    <ul class="mb-4">
      <li>На данный момент пополнение баланса не работает. Когда будет работать и будет ли вообще - неизвестно.</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Blocking',
}
</script>

<style>

</style>