<template>
  <v-col cols="12" class="mt-4">
    <v-progress-circular
      class="mx-auto"
      :size="50"
      :width="5"
      color="primary"
      indeterminate
      style="display: block;"
    ></v-progress-circular>
  </v-col>
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<style>

</style>